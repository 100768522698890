import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { Track, Playlist } from '@/service/types';

@Module({ name: 'player', namespaced: true })
export class PlayerModule extends VuexModule {
  // ----------------------------------------
  // tracks
  private _tracks: Track[] = [];

  public get tracks() {
    return this._tracks;
  }

  @Mutation
  public setTracks(value: Track[]) {
    this._tracks = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // currentTrackIndex
  private _currentTrackIndex: number | null = null;

  public get currentTrackIndex() {
    return this._currentTrackIndex;
  }

  @Mutation
  public setcurrentTrackIndex(value: number | null) {
    this._currentTrackIndex = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // position
  private _position: number = 0;

  public get position() {
    return this._position;
  }

  @Mutation
  public setPosition(value: number) {
    this._position = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // volume
  private _volume: number = 0.5;

  public get volume() {
    return this._volume;
  }

  @Mutation
  public setVolume(value: number) {
    this._volume = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // muted
  private _muted: boolean = false;
  
  public get muted() {
    return this._muted;
  }
  
  @Mutation
  public setMuted(value: boolean) {
    this._muted = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // playlist
  private _playlist: Playlist | null = null;
  
  public get playlist() {
    return this._playlist;
  }
  
  @Mutation
  public setPlaylist(value: Playlist | null) {
    this._playlist = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // loading
  private _loading: boolean = false;
  
  public get loading() {
    return this._loading;
  }
  
  @Mutation
  public setLoading(value: boolean) {
    this._loading = value;
  }
  // ----------------------------------------
}
