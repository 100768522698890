export const enum ClassType {
  Flex = 'flex',
  HighEnergy = 'highEnergy',
  Lift = 'lift',
  Circuits = 'circuits',
  Tone = 'tone',
  Spin = 'spin',
  Custom = 'custom'
}

export const enum PlaylistLength {
  Mins30 = 30,
  Mins45 = 45
}

export const lengthColors: { [key in PlaylistLength]: string } = {
  '30': 'blue',
  '45': 'purple'
};

export interface TypeColour {
  primary: string;
  secondary: string;
}

export const classColors: { [key in ClassType]: TypeColour } = {
  flex: {
    primary: 'blue',
    secondary: 'orange'
  },
  circuits: {
    primary: 'pink',
    secondary: 'blue'
  },
  highEnergy: {
    primary: 'pink',
    secondary: 'blue'
  },
  lift: {
    primary: 'lime',
    secondary: 'lime'
  },
  tone: {
    primary: 'blue',
    secondary: 'orange'
  },
  spin: {
    primary: 'lime',
    secondary: 'orange'
  },
  custom: {
    primary: 'lime',
    secondary: 'orange'
  }
};

export const colors: string[] = ['cc-blue', 'cc-orange', 'cc-pink', 'cc-lime'];

export const classTypes: ClassType[] = [
  ClassType.Flex,
  ClassType.Lift,
  ClassType.Circuits,
  ClassType.HighEnergy,
  ClassType.Tone,
  ClassType.Spin
];

export const playlistLengths: PlaylistLength[] = [
  PlaylistLength.Mins30,
  PlaylistLength.Mins45
];

export interface Playlist {
  [key: string]: any;
  id: string;
  userId?:string;
  name: string;
  description?: string;
  tracks: string[];
  classTypes: ClassType[];
  lengthType?: PlaylistLength;
  length?: number;
  deleted?: boolean;
  energyLevel?: string;
  bpm?: {
    min: number;
    max: number;
  };
}

export interface Track {
  id: string;
  title?: string;
  artist?: string;
  duration?: number;
  url?: string;
  bpm?: string;
  genre?: string;
  energyLevel?: string;
  sha256?: string;
  path?: string;
}

export interface Genre {
  name: string;
  color: string;
  subgenres?: Genre[];
}

export interface Mood {
  name: string;
  color: string;
}

export interface EnergyLevel {
  name: 'low' | 'medium' | 'high';
  color: string;
}

export interface Meta {
  genres: Genre[];
  moods: Mood[];
  energyLevels: EnergyLevel[];
}

export interface Waveform {
  trackId?: string;
  bits: number;
  data: number[];
  length?: number;
}

export interface PlaylistHistory {
  playlistId: string;
  lastSaved?: Date;
  history: Track[][];
}
