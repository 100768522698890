
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from './store/modules/settings';
import { GuiModule } from './store/modules/gui';
import { isAccessDeniedCode } from './service/api';
import { UserModule } from './store/modules/user';
import { logout, errorEventBus } from './service';

@Component
export default class Main extends Vue {
  handleError(error: any): boolean | undefined {
    const gui = getModule(GuiModule, this.$store);
    const user = getModule(UserModule, this.$store);

    gui.setSnackbarColor('error');

    if (
      error?.response?.status === 401 ||
      isAccessDeniedCode(error?.response?.data?.code)
    ) {
      if (user.token) {
        logout();

        gui.setSnackbarColor('primary');
        gui.setSnackbarText(this.$t('common.error.loggedOut').toString() + '!');

        this.$router.push('/login');

        console.warn(error);
        return false;
      }

      gui.setSnackbarText(
        this.$t('common.error.accessDenied').toString() + '!'
      );
      console.warn(error);
      return false;
    }

    gui.setSnackbarText(this.$t('common.error.unexpected').toString() + '!');
    console.error(error);

    return false;
  }

  errorCaptured(error: any, vm: Vue, info: any) {
    return this.handleError(error);
  }

  get soulSold() {
    const settings = getModule(SettingsModule, this.$store);
    return settings.soulSold;
  }

  get snackbar() {
    const gui = getModule(GuiModule, this.$store);

    return gui.snackbarText !== null;
  }

  set snackbar(v: boolean) {
    if (!v) {
      const gui = getModule(GuiModule, this.$store);
      gui.setSnackbarText(null);
    }
  }

  get snackbarText() {
    const gui = getModule(GuiModule, this.$store);

    return gui.snackbarText;
  }

  get snackbarColor() {
    const gui = getModule(GuiModule, this.$store);

    return gui.snackbarColor;
  }

  acceptCookie() {
    const settings = getModule(SettingsModule, this.$store);
    settings.setSoulSold(true);
  }

  created() {
    errorEventBus.$on('error', (e: any) => this.handleError(e));
  }
}
