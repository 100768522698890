import Vue from 'vue';
import Main from './Main.vue';
import './registerServiceWorker';
import { router } from './router';
import { store } from './store';

import { vuetify } from './plugins/vuetify';
import { i18n } from './plugins/i18n';
import './plugins/portal';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import { login } from './service';
import 'vue-class-component/hooks';

Vue.config.productionTip = false;

async function main() {
  // Try to log in with saved token.
  await login();

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(Main)
  }).$mount('#app');
}

main();
