import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import SecureLS from 'secure-ls';

Vue.use(Vuex);

import { GuiModule } from './modules/gui';
import { SettingsModule } from './modules/settings';
import { UserModule } from './modules/user';
import { PlayerModule } from './modules/player';
import { EditorModule } from './modules/editor';

const secureStorage = new SecureLS({
  encodingType: 'AES',
  encryptionSecret: 'sKUmMX3GSiGAQ2gZK1EQ3UAjzPY5Av4X'
});

const vuexLocalStorage = new VuexPersistence({
  key: 'cc-persistence',
  modules: ['settings', 'user', 'player'],
  saveState: (key, state) => {
    try {
      return secureStorage.set(key, state);
    } catch (e) {
      console.warn(e);
    }

    // Use local storage without obfuscation
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (e) {
      console.error(e);
    }
  },
  restoreState: key => {
    try {
      return secureStorage.get(key);
    } catch (e) {
      console.warn(e);
    }

    // Use local storage without obfuscation
    const stateStr = localStorage.getItem(key);

    if (!stateStr) {
      return;
    }

    try {
      return JSON.parse(stateStr);
    } catch (e) {
      console.error(e);
    }
  }
});

export const store = new Vuex.Store({
  modules: {
    gui: GuiModule,
    settings: SettingsModule,
    user: UserModule,
    player: PlayerModule,
    editor: EditorModule
  },
  plugins: [vuexLocalStorage.plugin],

  strict: (process.env.DEV as any) || false
});
