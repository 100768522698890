import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ name: 'settings', namespaced: true })
export class SettingsModule extends VuexModule {
  private _darkTheme: boolean = true;

  // Cookie consent
  private _soulSold: boolean = false;

  @Mutation
  public toggleDarkTheme(on: boolean) {
    this._darkTheme = on;
  }

  public get darkTheme() {
    return this._darkTheme;
  }

  @Mutation
  public setSoulSold(on: boolean) {
    this._soulSold = on;
  }

  public get soulSold() {
    return this._soulSold;
  }

  // ----------------------------------------
  // bottomPlayer
  private _bottomPlayer: boolean = true;
  
  public get bottomPlayer() {
    return this._bottomPlayer;
  }
  
  @Mutation
  public setBottomPlayer(value: boolean) {
    this._bottomPlayer = value;
  }
  // ----------------------------------------
}
