import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import { UserModule } from '@/store/modules/user';
import { store } from '@/store';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/app',
    component: () => import('@/views/App.vue'),
    children: [
      {
        path: 'playlists',
        component: () => import('@/views/app/playlists/Playlists.vue'),
        children: [
          {
            path: 'browse/:playlistId?',
            name: 'managePlaylists',
            component: () => import('@/views/app/playlists/Manage.vue')
          },
          {
            path: 'edit/:playlistId?',
            name: 'editPlaylist',
            component: () => import('@/views/app/playlists/Edit.vue')
          }
        ]
      },
      {
        path: 'tracks',
        name: 'tracks',
        component: () => import('@/views/app/Tracks.vue')
      },
      {
        path: 'player',
        name: 'player',
        component: () => import('@/views/app/player/Player.vue')
      },
      {
        path: '*',
        redirect: 'playlists/browse'
      }
    ]
  },
  {
    path: '*',
    redirect: '/app/playlists/browse'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _from, next) => {
  const user = getModule(UserModule, store);

  if (user.token === null) {
    if (to.path === '/login') {
      return next();
    }

    return next('/login');
  }

  if (to.path === '/login') {
    return next('/app');
  }

  return next();
});

export { router };
