import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { Playlist, Track } from '@/service/types';

@Module({ name: 'editor', namespaced: true })
export class EditorModule extends VuexModule {
  // ----------------------------------------
  // history
  private _history: Track[][] = [];

  public get history() {
    return this._history;
  }

  @Mutation
  public setHistory(value: Track[][]) {
    this._history = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // lastSaved
  private _lastSaved: Date | null = null;
  
  public get lastSaved() {
    return this._lastSaved;
  }
  
  @Mutation
  public setLastSaved(value: Date | null) {
    this._lastSaved = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // targetPlaylistId
  private _targetPlaylistId: string = '';

  public get targetPlaylistId() {
    return this._targetPlaylistId;
  }

  @Mutation
  public setTargetPlaylistId(value: string) {
    this._targetPlaylistId = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // targetPlaylist
  private _targetPlaylist: Playlist | null = null;

  public get targetPlaylist() {
    return this._targetPlaylist;
  }

  @Mutation
  public setTargetPlaylist(value: Playlist | null) {
    this._targetPlaylist = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // targetPlaylistTracks
  private _targetPlaylistTracks: Track[] = [];

  public get targetPlaylistTracks() {
    return this._targetPlaylistTracks;
  }

  @Mutation
  public setTargetPlaylistTracks(value: Track[]) {
    this._targetPlaylistTracks = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // sourcePlaylistId
  private _sourcePlaylistId: string = '';

  public get sourcePlaylistId() {
    return this._sourcePlaylistId;
  }

  @Mutation
  public setSourcePlaylistId(value: string) {
    this._sourcePlaylistId = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // sourcePlaylist
  private _sourcePlaylist: Playlist | null = null;

  public get sourcePlaylist() {
    return this._sourcePlaylist;
  }

  @Mutation
  public setSourcePlaylist(value: Playlist | null) {
    this._sourcePlaylist = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // sourcePlaylistTracks
  private _sourcePlaylistTracks: Track[] = [];

  public get sourcePlaylistTracks() {
    return this._sourcePlaylistTracks;
  }

  @Mutation
  public setSourcePlaylistTracks(value: Track[]) {
    this._sourcePlaylistTracks = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // rightPanel
  private _rightPanel: boolean = false;
  
  public get rightPanel() {
    return this._rightPanel;
  }
  
  @Mutation
  public setRightPanel(value: boolean) {
    this._rightPanel = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // sourcePlaylistEnabled
  private _sourcePlaylistEnabled: boolean = false;
  
  public get sourcePlaylistEnabled() {
    return this._sourcePlaylistEnabled;
  }
  
  @Mutation
  public setSourcePlaylistEnabled(value: boolean) {
    this._sourcePlaylistEnabled = value;
  }
  // ----------------------------------------
  
}
