import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { Meta, Waveform } from '@/service/types';

@Module({ name: 'gui', namespaced: true })
export class GuiModule extends VuexModule {
  private _drawer: boolean = true;

  @Mutation
  public toggleDrawer(on: boolean) {
    this._drawer = on;
  }

  public get drawer() {
    return this._drawer;
  }

  // ----------------------------------------
  // drawerEnabled
  private _drawerEnabled: boolean = false;

  public get drawerEnabled() {
    return this._drawerEnabled;
  }

  @Mutation
  public setDrawerEnabled(value: boolean) {
    this._drawerEnabled = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // rightDrawer
  private _rightDrawer: boolean = true;

  public get rightDrawer() {
    return this._rightDrawer;
  }

  @Mutation
  public setRightDrawer(value: boolean) {
    this._rightDrawer = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // rightDrawerEnabled
  private _rightDrawerEnabled: boolean = false;

  public get rightDrawerEnabled() {
    return this._rightDrawerEnabled;
  }

  @Mutation
  public setRightDrawerEnabled(value: boolean) {
    this._rightDrawerEnabled = value;
  }
  // ----------------------------------------

  private _snackbarText: string | null = null;

  public get snackbarText() {
    return this._snackbarText;
  }

  @Mutation
  public setSnackbarText(value: string | null) {
    this._snackbarText = value;
  }

  private _snackbarColor: string = 'primary';

  public get snackbarColor() {
    return this._snackbarColor;
  }

  @Mutation
  public setSnackbarColor(value: string) {
    this._snackbarColor = value;
  }

  // ----------------------------------------
  // meta
  private _meta: Meta | null = null;

  public get meta() {
    return this._meta;
  }

  @Mutation
  public setMeta(value: Meta | null) {
    this._meta = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // waveform
  private _waveform: Waveform | null = null;

  public get waveform() {
    return this._waveform;
  }

  @Mutation
  public setWaveform(value: Waveform | null) {
    this._waveform = value;
  }
  // ----------------------------------------

  // ----------------------------------------
  // playing
  private _playing: boolean = false;
  
  public get playing() {
    return this._playing;
  }
  
  @Mutation
  public setPlaying(value: boolean) {
    this._playing = value;
  }
  // ----------------------------------------
}
