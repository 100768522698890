import { ClassType, PlaylistLength } from '@/service/types';

export default {
  cookieConsent: {
    title: 'Cookie Consent',
    text: `We might use cookies on this site to store data in your browser.

        The data contains no personal information, and is only used for security purposes and enhancing user experience.`,
    accept: 'I understand'
  },
  common: {
    close: 'Close',
    error: {
      accessDenied: 'Access denied',
      unexpected: 'We are sorry, an unexpected error happened',
      loggedOut: 'Please log in again'
    },
    validation: {
      fieldRequired: 'This field is required'
    }
  },
  app: {
    changeTheme: 'Toggle Dark Theme',
    toggleBottomPlayer: 'Toggle Player Controls',
    menu: {
      player: 'Now Playing',
      playlists: 'Playlists',
      tracks: 'Tracks',
      logout: 'Log out',
      browsePlaylists: 'Browse',
      editPlaylists: 'Edit'
    },
    player: {
      previous: 'Previous Track',
      play: 'Play',
      pause: 'Pause',
      next: 'Next Track',
      searchTrack: 'Search For Track',
      goToPlaylist: 'Go To Playlist',
      mute: 'Mute',
      unmute: 'Unmute',
      savePlaylist: 'Create Playlist',
      savePlaylistTooltip: 'Create playlist with the current tracks',
      untitled: 'No Playlist',
      noTrack: 'No Track Playing',
      openPlayer: 'Open Player',
      dialog: {
        save: {
          title: 'Create New Playlist',
          playlistName: 'Playlist Name',
          create: 'Create',
          description: 'Description',
          validation: {
            nameRequired: 'Playlist name is required'
          }
        }
      }
    },
    tracks: {
      table: {
        page: 'Page',
        tracksPerPage: 'Tracks per page',
        noData: 'No tracks available',
        headers: {
          artist: 'Artist',
          title: 'Title',
          genre: 'Genre',
          energyLevel: 'Energy Level',
          mood: 'Mood',
          bpm: 'BPM',
          duration: 'Duration'
        },
        playTrack: 'Play Track',
        deleteTrack: 'Delete Track',
        addTrack: 'Add To Playlist',
        queueTrack: 'Queue Track'
      },
      bpm: 'Track BPM',
      fieldFilters: 'Filter By Fields',
      enableFilter: 'Enable this filter',
      toggleAllFilters: 'Toggle all filters',
      filters: {
        artist: 'Artist',
        title: 'Title',
        genre: 'Genre',
        energyLevel: 'Energy Level',
        mood: 'Mood',
        generic: 'Search Tracks'
      }
    },
    classTypes: <{ [key in ClassType]: string }>{
      circuits: 'Circuits',
      highEnergy: 'High Energy',
      flex: 'Flex',
      lift: 'Lift',
      tone: 'Tone',
      spin: 'Spin',
      custom: 'Custom'
    },
    playlistLengths: <{ [key in PlaylistLength]: string }>{
      '30': '30 Minutes',
      '45': '45 Minutes'
    },
    playlists: {
      official: 'Official Playlists',
      custom: 'My Playlists',
      user: 'User Playlists',
      search: 'Search Playlists',
      notSelected: 'Select a playlist from the menu',
      noData: 'No playlists available',
      notFound: 'No playlists found',
      showDeleted: 'Show deleted',
      duration: 'Total duration',
      editPlaylistTooltip: 'Edit Playlist Info',
      actions: {
        create: 'New Playlist',
        delete: 'Delete',
        restore: 'Restore',
        edit: 'Add Or Delete Tracks',
        play: 'Play'
      },
      edit: {
        targetPlaylist: 'Select a playlist to edit',
        notSelected: 'Select a playlist to edit',
        rightPanel: 'Add Tracks',
        addAllTracks: 'Add All',
        closeRightPanel: 'Done',
        enableSourcePlaylist: 'From another playlist',
        sourcePlaylist: 'Playlist to browse',
        save: 'Save Playlist',
        lastSaved: 'Last saved at',
        restore: 'Undo'
      },
      dialog: {
        create: {
          title: 'Create New Playlist',
          playlistName: 'Playlist Name',
          create: 'Create',
          sourcePlaylist: 'Existing Playlist',
          fromSourcePlaylist: 'From Existing Playlist',
          description: 'Description',
          validation: {
            nameRequired: 'Playlist name is required',
            fieldRequired: 'Th is field is required'
          }
        },
        edit: {
          title: 'Edit Playlist',
          playlistName: 'Playlist Name',
          create: 'Create',
          description: 'Description',
          validation: {
            nameRequired: 'Playlist name is required'
          },
          save: 'Save'
        },
        delete: {
          title: 'Delete {playlistName}?',
          text:
            'The playlist will be marked for deletion. You can undo this action before it is actually deleted from the server (approximately 14 days).',
          confirm: 'Confirm'
        }
      }
    }
  },
  login: {
    login: 'Login',
    username: 'Username',
    password: 'Password',
    tooShort: 'Input is too short',
    fieldRequired: 'This cannot be empty'
  }
};
