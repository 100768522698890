import axios, { AxiosResponse, CancelToken } from 'axios';
import { Track, Playlist, ClassType, Waveform } from '../types';
import _snakeCase from 'lodash/snakeCase';
import { LayoutRoot } from '../layout/decl';

const API_URL: string = (() => {
  let url: string = process.env.VUE_APP_API_URL as any;
  if (url.slice(-1) !== '/') {
    url += '/';
  }
  return url;
})();

export interface GenericError {
  code?: string;
  message?: string;
}

export class APIError extends Error {}

export interface Response<Result, Error = GenericError> {
  status: 'ok' | 'error';
  result?: Result;
  error?: Error;
}

function getResult<T = any>(res: AxiosResponse<Response<T>>): T {
  if (!res.data.result) {
    if (!res.data.error) {
      throw new APIError('result is empty, and no error returned');
    }
    throw new APIError(res.data.error.code);
  }
  return res.data.result;
}

export interface RawPlaylist {
  playlist_id: string;
  user_id: string;
  name: string;
  description: string;
  meta: string;
  tracks: string[];
  deleted: boolean;
  created_at: number;
}

interface PlaylistMeta {
  classTypes: ClassType[];
  classType?: ClassType; // TODO: response sometimes contains this instead of classTypes
  bpmType?: string; // A range like 100-120
  lengthType?: number;
}

export interface AddPlaylistResponse {
  playlist_id: string;
}

function transformPlaylist(p: RawPlaylist): Playlist {
  let plist: Playlist = {
    id: p.playlist_id,
    userId: p.user_id,
    name: p.name,
    tracks: p.tracks || [],
    description: p.description,
    deleted: p.deleted,
    classTypes: [ClassType.Custom]
  };

  const meta: PlaylistMeta = JSON.parse(p.meta);

  if (meta.bpmType) {
    const bpms = meta.bpmType
      .trim()
      .split('-')
      .map(p => parseInt(p));

    plist.bpm = {
      min: Math.min(...bpms),
      max: Math.max(...bpms)
    };
  }

  if (meta.classTypes) {
    plist.classTypes = meta.classTypes;
  } else if (meta.classType) {
    plist.classTypes = [meta.classType];
  }

  return { ...p, ...plist };
}

export async function getPlaylists(userId?: string): Promise<Playlist[]> {
  const res = await axios.post<Response<RawPlaylist[]>>(
    new URL(`private/listplaylists`, API_URL).href,
    userId
      ? {
          user_id: userId
        }
      : {}
  );

  return getResult(res).map(transformPlaylist);
}

export async function getPlaylist(id: string): Promise<Playlist> {
  const res = await axios.post<Response<RawPlaylist>>(
    new URL(`private/getplaylist`, API_URL).href,
    {
      playlist_id: id
    }
  );

  return transformPlaylist(getResult(res));
}

export async function addPlaylist(
  playlist: Playlist,
  userId?: string
): Promise<AddPlaylistResponse> {
  return getResult(
    await axios.post<Response<AddPlaylistResponse>>(
      new URL(`private/addplaylist`, API_URL).href,
      {
        name: playlist.name,
        description: playlist.description,
        tracks: playlist.tracks,
        ...(userId ? { user_id: userId } : {})
      }
    )
  );
}

export async function updatePlaylist(
  playlist: Playlist,
  cancelToken?: CancelToken
): Promise<void> {
  return getResult(
    await axios.post<Response<void>>(
      new URL(`private/updateplaylist`, API_URL).href,
      {
        name: playlist.name,
        playlist_id: playlist.id,
        description: playlist.description,
        tracks: playlist.tracks.map(t => t.toString())
      },
      { cancelToken }
    )
  );
}

export async function removePlaylist(id: string): Promise<void> {
  const res = await axios.post<Response<void>>(
    new URL(`private/removeplaylist`, API_URL).href,
    {
      playlist_id: id
    }
  );

  return getResult(res);
}

export async function restorePlaylist(id: string): Promise<void> {
  const res = await axios.post<Response<void>>(
    new URL(`private/restoreplaylist`, API_URL).href,
    {
      playlist_id: id
    }
  );

  return getResult(res);
}

export interface LoginResult {
  access_token: string;
  refresh_token: string;
}

export async function login(username: string, password: string) {
  const res = await axios.post<Response<LoginResult>>(
    new URL(`public/login`, API_URL).href,
    {
      username,
      password
    }
  );

  return getResult(res);
}

export interface User {
  user_id: string;
  email_address: string;
}

export async function getUsers() {
  const res = await axios.post<Response<User[]>>(
    new URL(`private/listappusers`, API_URL).href
  );

  return getResult(res);
}

export interface TrackSearchOptions {
  bpm_min?: number;
  bpm_max?: number;
  filters?: Filter[];
  order?: string;
  order_by?: string;
  page?: number;
  per_page?: number;
}

export interface Filter {
  filter: string;
  values: string[];
}

interface RawTrackSearchResult {
  page: number;
  per_page: number;
  items: number;
  data: RawTrack[];
}

export interface TrackSearchResult {
  page: number;
  perPage: number;
  items: number;
  tracks: Track[];
}

interface RawTrack {
  track_id: string;
  title: string;
  composer_name: string;
  duration: number;
  genre: string;
  energy_level: string;
  bpm: string;
  url: string;
  created_at: number;
}

export async function getTracks(...trackIds: string[]): Promise<Track[]> {
  const res = await axios.post<Response<RawTrack[]>>(
    new URL(`private/gettrackfiles`, API_URL).href,
    {
      tracks: trackIds
    }
  );

  const tracks = getResult(res).map(transformTrack);

  return trackIds.map(id => tracks.find(t => t.id === id)!);
}

const postFieldMap: { [key: string]: string } = {
  artist: 'composer_name'
};

export async function searchTracks(
  options: TrackSearchOptions
): Promise<TrackSearchResult> {
  options.filters = options.filters?.map(f => {
    let filter = _snakeCase(f.filter).trim();

    if (postFieldMap[filter]) {
      filter = postFieldMap[filter];
    }

    return {
      filter,
      values: f.values
    };
  });

  if (options.order_by) {
    options.order_by = _snakeCase(options.order_by).trim();

    if (postFieldMap[options.order_by]) {
      options.order_by = postFieldMap[options.order_by];
    }
  }

  const res = await axios.post<Response<RawTrackSearchResult>>(
    new URL(`private/searchlibrary`, API_URL).href,
    options
  );

  return transformTrackResult(getResult(res));
}

function transformTrackResult(search: RawTrackSearchResult): TrackSearchResult {
  return {
    page: search.page,
    perPage: search.per_page,
    items: search.items,
    tracks: search.data.map(transformTrack)
  };
}

function transformTrack(t: RawTrack): Track {
  return {
    artist: t.composer_name,
    duration: t.duration,
    id: t.track_id,
    url: t.url,
    title: t.title,
    bpm: t.bpm,
    genre: t.genre,
    energyLevel: t.energy_level
  };
}

export interface Tag {
  type: 'genre' | 'mood';
  label: string;
  sublabel?: string;
}

export async function getTags(): Promise<Tag[]> {
  const res = await axios.post<Response<Tag[]>>(
    new URL(`private/listfiltertags`, API_URL).href
  );

  return getResult(res);
}

export async function getWaveform(track: Track): Promise<Waveform> {
  if (!track.url) {
    throw new Error(`url missing for track ${track.id}`);
  }

  const res = await axios.get<Waveform>(track.url + '.waveform.3.json');

  if (typeof res.data === 'string') {
    throw new Error('nginx successfully failed');
  }

  return res.data;
}

export function isAccessDeniedCode(code?: string): boolean {
  const codes = ['EREG_badusr_E002', 'EGEN_lockedout_E001', 'EGEN_unauth_E001'];

  for (const c of codes) {
    if (code === c) {
      return true;
    }
  }

  return false;
}

export async function getLayout(): Promise<LayoutRoot> {
  const res = await axios.post<Response<LayoutRoot>>(
    new URL('private/getlayout', API_URL).href
  );

  return getResult(res);
}
