import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import { store } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from '@/store/modules/settings';

Vue.use(Vuetify);

export const vuetify = new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        dark: getModule(SettingsModule, store).darkTheme,
        themes: {
            dark: {
                primary: '#2e94ff',
                secondary: '#ff8e43',
                accent: '#9ec217',
                error: '#f3181e',
                info: '#3b7fff',
                success: '#13ae03',
                warning: '#ffdb00'
            },
            light: {
                primary: '#2e94ff',
                secondary: '#ff8e43',
                accent: '#9ec217',
                error: '#f3181e',
                info: '#3b7fff',
                success: '#13ae03',
                warning: '#ffdb00'
            }
        }
    }
});
