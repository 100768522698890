import { store } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { UserModule } from '@/store/modules/user';
import * as api from './api';
import axios from 'axios';
import { Meta, Genre, EnergyLevel } from './types';
import { GuiModule } from '@/store/modules/gui';
import Vue from 'vue';
import jwt from 'jsonwebtoken';

export async function logout() {
  const user = getModule(UserModule, store);
  user.setToken(null);
  user.setUserId(null);
}

export interface LoginCredentials {
  username: string;
  password: string;
}

export async function login(credentials?: LoginCredentials) {
  const user = getModule(UserModule, store);

  if (credentials) {
    const res = await api.login(credentials.username, credentials.password);

    user.setToken(res.access_token);
    // TODO user id is apparently not the username
    user.setUserId(null);
    user.setSelectedUserId(null);
    user.setAdmin(isAdmin(res.access_token));

    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${res.access_token}`;
    return;
  }

  if (user.token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
  }
}

export const allowedGenres = [
  'electronica & dance',
  'hip hop',
  'pop',
  'small emotions',
  'acoustic',
  'rock',
  'funk',
  'classical',
  'rnb & soul',
  'jazz',
  'country',
  'latin',
  'beats'
];

export function isAdmin(token: string): boolean {
  try {
    return (jwt.decode(token, { json: true })?.privileges & 32) !== 0;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getMeta(): Promise<Meta> {
  const gui = getModule(GuiModule, store);

  if (gui.meta) {
    return gui.meta;
  }

  const tags = await api.getTags();

  const meta = {
    genres: tags
      .filter(tag => tag.type === 'genre')
      .reduce<Genre[]>((genres, tag) => {
        let genre = genres.find(g => g.name === tag.label);

        if (!genre) {
          genre = {
            name: tag.label.toLowerCase().trim(),
            color: 'cc-blue'
          };

          genres.push(genre);
        }

        if (tag.sublabel) {
          const subgenre = {
            name: tag.sublabel.toLowerCase().trim(),
            color: 'cc-blue'
          };

          if (!genre.subgenres) {
            genre.subgenres = [subgenre];
          } else {
            genre.subgenres.push(subgenre);
          }
        }

        return genres;
      }, [])
      .sort((g1, g2) =>
        g1.name.toLowerCase().localeCompare(g2.name.toLowerCase())
      ),

    moods: tags
      .filter(tag => tag.type === 'mood')
      .map(tag => {
        return {
          name: tag.label,
          color: 'cc-pink'
        };
      })
      .sort((m1, m2) =>
        m1.name.toLowerCase().localeCompare(m2.name.toLowerCase())
      ),

    energyLevels: [
      {
        name: 'low',
        color: 'cc-lime'
      },
      {
        name: 'medium',
        color: 'cc-lime'
      },
      {
        name: 'high',
        color: 'cc-lime'
      }
    ] as EnergyLevel[]
  };

  gui.setMeta(meta);

  // WARNING: this might cause wtfs in the future, the client
  // specifically requested "beats" to be a genre.
  meta.genres.push({
    name: 'beats',
    color: 'cc-blue'
  });

  return meta;
}

export const errorEventBus = new Vue();
