import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { User } from '@/service/api';

@Module({ name: 'user', namespaced: true })
export class UserModule extends VuexModule {
  private _token: string | null = null;
  private _userId: string | null = null;

  public get token() {
    return this._token;
  }

  @Mutation
  public setToken(token: string | null) {
    this._token = token;
  }

  public get userId() {
    return this._userId;
  }

  @Mutation
  public setUserId(id: string | null) {
    this._userId = id;
  }

  // ----------------------------------------
  // admin
  private _admin: boolean = false;
  
  public get admin() {
    return this._admin;
  }
  
  @Mutation
  public setAdmin(value: boolean) {
    this._admin = value;
  }
  // ----------------------------------------
  
  // ----------------------------------------
  // selectedUserId
  private _selectedUserId: string | null = null;
  
  public get selectedUserId() {
    return this._selectedUserId;
  }
  
  @Mutation
  public setSelectedUserId(value: string | null) {
    this._selectedUserId = value;
  }
  // ----------------------------------------
}
